import Add16 from "@carbon/icons-react/es/add/16";
import { Col, Row, Tabs } from "antd";
import FroalaEditor from "components/FroalaEditor/FroalaEditor";
import { WorkflowDefinitionFilter } from "models/WorkflowDefinition";
import { WorkflowStepFilter } from "models/WorkflowStep";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormItem,
  InputText,
  Modal,
  StandardTable,
} from "react3l-ui-library";
import { workflowDirectionRepository } from "repositories/workflow-direction-repository";
import { utilService } from "services/common-services/util-service";
import { fieldService } from "services/page-services/field-service";
import { queryStringService } from "services/page-services/query-string-service";
import nameof from "ts-nameof.macro";
import { useWorkflowDirectionConditionTableHook } from "./WorkflowDirectionConditionHook";
import workflowDirectionDetailStyle from "./WorkflowDirectionDetailModal.module.scss";
import { WorkflowDirectionDetailModalProps } from "./WorkflowDirectionDetailModalHook";
const { TabPane } = Tabs;

function WorkflowDirectionDetailModal(
  props: WorkflowDirectionDetailModalProps
) {
  const [translate] = useTranslation();

  const { visible, handleClose, handleSave, model, dispatch } = props;

  const [workflowStepFilter, setWorkflowStepFilter] =
    React.useState<WorkflowStepFilter>(new WorkflowStepFilter());

  const params = queryStringService.useGetQueryString("workflowDefinitionId");

  const { handleChangeAllField, handleChangeSingleField } =
    fieldService.useField(model, dispatch);

  const handleChangeNotificationContent = React.useCallback(
    (fieldName: string) => {
      return (value: any) => {
        const newModel = { ...model };
        newModel[fieldName] = value.currentTarget.value;
        handleChangeAllField(newModel);
      };
    },
    [handleChangeAllField, model]
  );

  React.useEffect(() => {
    if (model.workflowDefinitionId) {
      workflowStepFilter.workflowDefinitionId.equal =
        model.workflowDefinitionId;
      setWorkflowStepFilter({
        ...workflowStepFilter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const ref = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (ref.current && !model?.id) {
      const tempModel = { ...model };
      if (params?.workflowDefinitionId) {
        const filter = new WorkflowDefinitionFilter();
        filter.id.equal = params?.workflowDefinitionId;
        workflowDirectionRepository
          .singleListWorkflowDefinition(filter)
          .subscribe((res) => {
            tempModel.workflowDefinition = res[0];
            tempModel.workflowDefinitionId = res[0].id;
            tempModel.statusId = 1;
            handleChangeAllField(tempModel);
          });
      } else {
        tempModel.statusId = 1;
        handleChangeAllField(tempModel);
      }

      ref.current = false;
    }
  }, [model, handleChangeAllField, params?.workflowDefinitionId]);

  const { list, columns, handleAddRow } =
    useWorkflowDirectionConditionTableHook(model, dispatch);

  return (
    <Modal
      size={1024}
      visibleFooter={true}
      visible={visible}
      title={translate("workflowDirections.titleModal")}
      handleCancel={handleClose}
      handleSave={handleSave}
      titleButtonCancel={translate("general.actions.cancel")}
      titleButtonApply={translate("general.actions.save")}
      className={workflowDirectionDetailStyle["workflow-direction-detail"]}
    >
      <Tabs defaultActiveKey="0">
        <TabPane
          key="0"
          tab={translate("workflowDirections.tabs.workflowDirectionCondition")}
        >
          <Row className="p-t--xxs">
            <Col lg={24}>
              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  className="btn--lg"
                  icon={<Add16 />}
                  onClick={handleAddRow}
                >
                  {translate("workflowDirections.button.addCondition")}
                </Button>
              </div>
              <div className="condition-table">
                <StandardTable
                  rowKey={nameof(list[0].key)}
                  columns={columns}
                  dataSource={list}
                  tableSize={"lg"}
                />
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane key="1" tab={translate("workflowDirections.tabs.mailConfig")}>
          <Row className="m-t--xs">
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate("workflowDirections.titles.mailForCurrentStep")}
              </div>
              <Row>
                <Col lg={12}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.subjectMailForCurrentStep)
                    )}
                    message={model.errors?.subjectMailForCurrentStep}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.subjectMail")}
                      value={model.subjectMailForCurrentStep}
                      placeHolder={translate(
                        "workflowDirections.placeholder.subjectMail"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.subjectMailForCurrentStep),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className={`m-t--xs `}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.bodyMailForCurrentStep)
                    )}
                    message={model.errors?.bodyMailForCurrentStep}
                  >
                    <FroalaEditor
                      value={model.bodyMailForCurrentStep}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.bodyMailForCurrentStep),
                      })}
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="page-detail__title p-b--xxs">
                {translate("workflowDirections.titles.mailForCreator")}
              </div>
              <Row>
                <Col lg={12} className="">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.subjectMailForCreator)
                    )}
                    message={model.errors?.subjectMailForCreator}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.subjectMail")}
                      isMaterial={true}
                      value={model.subjectMailForCreator}
                      placeHolder={translate(
                        "workflowDirections.placeholder.subjectMail"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.subjectMailForCreator),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.bodyMailForCreator)
                    )}
                    message={model.errors?.bodyMailForCreator}
                  >
                    <FroalaEditor
                      value={model.bodyMailForCreator}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.bodyMailForCreator),
                      })}
                      placeholder={translate(
                        "workflowDirections.placeholder.bodyMailForCreator"
                      )}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate("workflowDirections.titles.mailForNextStep")}
              </div>
              <Row>
                <Col span={12}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.subjectMailForNextStep)
                    )}
                    message={model.errors?.subjectMailForNextStep}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.subjectMail")}
                      isMaterial={true}
                      value={model.subjectMailForNextStep}
                      placeHolder={translate(
                        "workflowDirections.placeholder.subjectMail"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.subjectMailForNextStep),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.bodyMailForNextStep)
                    )}
                    message={model.errors?.bodyMailForNextStep}
                  >
                    <FroalaEditor
                      value={model.bodyMailForNextStep}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.bodyMailForNextStep),
                      })}
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane key="2" tab={translate("workflowDirections.tabs.notiConfig")}>
          <Row className="m-t--xs">
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate(
                  "workflowDirections.titles.notificationForCurrentStep"
                )}
              </div>
              <Row>
                <Col lg={12}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.titleNotificationForCurrentStep)
                    )}
                    message={model.errors?.titleNotificationForCurrentStep}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.titleNotification")}
                      value={model.titleNotificationForCurrentStep}
                      placeHolder={translate(
                        "workflowDirections.placeholder.titleNotification"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(
                          model.titleNotificationForCurrentStep
                        ),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>

                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.contentNotificationForCurrentStep)
                    )}
                    message={model.errors?.contentNotificationForCurrentStep}
                  >
                    <textarea
                      id="noti_current_step"
                      name="noti_current_step"
                      onChange={handleChangeNotificationContent(
                        "contentNotificationForCurrentStep"
                      )}
                      value={
                        model.contentNotificationForCurrentStep
                          ? model.contentNotificationForCurrentStep
                          : ""
                      }
                      className="w-100"
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    ></textarea>
                  </FormItem>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <div className="page-detail__title p-b--xxs">
                {translate("workflowDirections.titles.notificationForCreator")}
              </div>
              <Row>
                <Col lg={12}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.titleNotificationForCreator)
                    )}
                    message={model.errors?.titleNotificationForCreator}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.titleNotification")}
                      isMaterial={true}
                      value={model.titleNotificationForCreator}
                      placeHolder={translate(
                        "workflowDirections.placeholder.titleNotification"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.titleNotificationForCreator),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.contentNotificationForCreator)
                    )}
                    message={model.errors?.contentNotificationForCreator}
                  >
                    {/* <FroalaEditor
                          value={model.contentNotificationForCreator}
                          onChange={handleChangeSingleField({
                            fieldName: nameof(
                              model.contentNotificationForCreator
                            ),
                          })}
                          placeholder={translate(
                            "workflowDirections.placeholder.content"
                          )}
                        /> */}
                    <textarea
                      id="noti_creator"
                      name="noti_creator"
                      onChange={handleChangeNotificationContent(
                        "contentNotificationForCreator"
                      )}
                      value={
                        model.contentNotificationForCreator
                          ? model.contentNotificationForCreator
                          : ""
                      }
                      className="w-100"
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    ></textarea>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-t--xs">
            <Col span={12} className="p-l--xxxs p-r--sm">
              <div className="page-detail__title p-b--xxs">
                {translate("workflowDirections.titles.notificationForNextStep")}
              </div>
              <Row>
                <Col span={12}>
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model,
                      nameof(model.titleNotificationForNextStep)
                    )}
                    message={model.errors?.titleNotificationForNextStep}
                  >
                    <InputText
                      maxLength={225}
                      showCount
                      label={translate("workflowDirections.titleNotification")}
                      isMaterial={true}
                      value={model.titleNotificationForNextStep}
                      placeHolder={translate(
                        "workflowDirections.placeholder.titleNotification"
                      )}
                      className={"tio-account_square_outlined"}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.titleNotificationForNextStep),
                      })}
                      type={0}
                    />
                  </FormItem>
                </Col>
                <Col lg={24} className="m-t--xs">
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      model.errors,
                      nameof(model.contentNotificationForNextStep)
                    )}
                    message={model.errors?.contentNotificationForNextStep}
                  >
                    {/* <FroalaEditor
                      value={model.contentNotificationForNextStep}
                      onChange={handleChangeSingleField({
                        fieldName: nameof(model.contentNotificationForNextStep),
                      })}
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    /> */}
                    <textarea
                      id="noti_next_step"
                      name="noti_next_step"
                      onChange={handleChangeNotificationContent(
                        "contentNotificationForNextStep"
                      )}
                      value={
                        model.contentNotificationForNextStep
                          ? model.contentNotificationForNextStep
                          : ""
                      }
                      className="w-100"
                      placeholder={translate(
                        "workflowDirections.placeholder.content"
                      )}
                    ></textarea>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default WorkflowDirectionDetailModal;
